export const defaultConfig = {

    // baseAPIUrl:"http://192.168.0.3:7000/api/", 
    // imagePath:"http://192.168.0.3:7000/images/", 
    
    // Live Url bt
    // baseAPIUrl:"https://nagain.etrueconcept.com/api/", 
    // imagePath:"https://nagain.etrueconcept.com/images/", 

    //  Live Url client
     baseAPIUrl:"https://n-again.com/api/", 
     imagePath:"https://n-again.com/images/", 

    };

    export const Usertype = {
        user:"User",
        driver:"Driver",
        restaurant:"Restaurant",
    }





































































    