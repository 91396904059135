
export default function LoadScreen(){
    return(
        <div className='loading'>
      <img src="/image/logo.png" alt="logo"/>
       
        <p className='mt-2'>Please wait we are fetching your data</p>
        </div>
    )
    }
    
    
    
    
    