import React from 'react'
import { Link } from 'react-router-dom'

export default function SplashScreen() {
  return (
    <>
    <>
  <div className="mobiletop-sec d-flex align-items-center justify-content-between">
  </div>
  <div className="mobcontainer splash">
    <div className="container mobileiddlesec ">
      <div className="text-center">
    
        <figure className="mb-0 thanksimg welcomeimg">
          <img src="/image/splash-logo-new.png" className="img-fluid h-auto w-auto" alt="" />
        </figure>
      </div>
     
    </div>
  </div>
</>

    </>
  )
}
