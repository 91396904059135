import { Actiontypes } from "../Action/ActionType";

let user={user:{receipt:'',likeDislike:'',itemList:
[],review:'',email:'',mobileno:'',termAndCondition:'',
reciptNumber:'',restaurantAddress:'',restaurantName:''
}}



export const UserData = (state = user, { type, payload }) => {
  switch (type) {
    case Actiontypes.USER:
      return { ...state, user: payload };
    default:
      return state;
  }
};




