import React, { useEffect, useState } from "react";
import { Usertype } from "../config";
import { AccountSvg, HomeSvg, ReviewSvg, HistorySvg, ProfileSvg, StoreFrontSvg } from "../SvgFile/Index";
import FooterLink from "./FooterLink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const [links, setLinks] = React.useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const activeBranch = JSON.parse(localStorage.getItem("activeBranch"))
  const token = localStorage.getItem("accessToken");
  const { t } = useTranslation();
  const location = useLocation()
  

  const user_links = [
    location.pathname.includes("product-likes") ||   location.pathname.includes("common-pairings") ? 
    {
      link: `/in_restaurants/details/${location.pathname.split("/")[3]}`,
      title: t("store"),
      icone: <StoreFrontSvg/>,
    } :  location.pathname.includes("likes") ? 
    {
      link: `/in_restaurants/details/${location.pathname.split("/")[2]}`,
      title: t("store"),
      icone: <StoreFrontSvg/>,
    } : {
      link: "/",
      title: t("Home"),
      icone: <HomeSvg/>,
    },
    {
      link: "/send-review",
      title: t("Review"),
      icone: <ReviewSvg />,
    },

    {
      link: token ? "/my-profile" : "/account-type",
      title: t("Account"),
      icone: <AccountSvg />,
    },
  ];

  
  
  const driver_links = [];
  const restaurant_links = [{
    link: "/history",
    title: t("History"),
    icone: <HistorySvg/>,
  },
  {
    link: token ? "/my-profile" : "/account-type",
    title: t("Account"),
    icone: <ProfileSvg/>,
  },

  {
    link: `/in_restaurants/details/${activeBranch}`,
    title: t("Store Front"),
    icone: <StoreFrontSvg/>,
  },];

  React.useEffect(() => {
    if (user?.userType === Usertype.restaurant) {
      setLinks(restaurant_links);
    } else if (user?.userType === Usertype.driver) {
      setLinks(driver_links);
    } else {
      setLinks(user_links);
    }
  }, [location.pathname]);

  const footerCss = () => {
    //mobilemenubottom
    if(user?.userType === Usertype.restaurant){
      return "newmenubottom"
    } else if(user?.userType === Usertype.driver){
      return ""
    } else return "mobilemenubottom"
  }


  

  return (
    <>
      <div className="footerbtm">
        <div className={`${footerCss()}  d-flex align-items-center justify-content-between`}>
          {links.map((item, i) => {
            return (
              <FooterLink
                key={i}
                href={item.link}
                name={item.title}
                icon={item.icone}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
