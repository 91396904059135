import React from 'react'
import { NavLink } from 'react-router-dom'

export default function FooterLink({ href, name, icon}) {
    
  return (
    <>
 <NavLink to={href} className={({ isActive, isPending }) =>
   isPending ? "" :  isActive ? "active" : ""
  }>
            {icon}
            {name}
          </NavLink>
    </>
  )
}
