import React, { Suspense, Fragment, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderLayout from "./Layout/HeaderLayout";
import Authenticated from "./Guard/Authenticated";
import Guest from "./Guard/Guest";
import BaseLayout from "./Layout/BaseLayout";
import LoadScreen from "./LoadScreen";
import RestaurantGuard, { RestaurantUserAccess } from "./Guard/RestaurantGuard";

export function RenderRout() {
  return (
    <>
      <Router>
        <Suspense fallback={<LoadScreen />}>
          <Routes>
            {routes?.map((route, i) => {
              const Guard = route?.guard || Fragment;
              const RestaurantUserAccess =
                route?.RestaurantUserAccess || Fragment;
              const RestaurantGuard = route?.RestaurantGuard || Fragment;
              const Layout = route?.layout || Fragment;
              const Component = route?.element;
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <Guard>
                      <RestaurantUserAccess>
                        <RestaurantGuard>
                          <Layout>
                            <Component />
                          </Layout>
                        </RestaurantGuard>
                      </RestaurantUserAccess>
                    </Guard>
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

const routes = [
  //Auth Pages
  {
    layout: BaseLayout,
    exact: true,
    path: "/send-review",
    element: lazy(() => import("./Component/Pages/SendReview/Index")),
  },
  {
    layout: BaseLayout,
    exact: true,
    path: "/review-show",
    element: lazy(() => import("./Component/Pages/SendReview/ReviewIntro")),
  },
  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: "/login/:type",
    element: lazy(() => import("./Component/Auth/Login")),
  },
  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: "/login/restaurant",
    element: lazy(() => import("./Component/Pages/RestaurantPanel/Login")),
  },
  {
    guard: Guest,
    layout: HeaderLayout,
    exact: true,
    path: "/account-type",
    element: lazy(() => import("./Component/Pages/Account/NoAccount")),
  },

  //Restaurants
  {
    layout: HeaderLayout,
    exact: true,
    path: "/",
    element: lazy(() => import("./Component/Pages/Restaurants/Index")),
  },

  {
    layout: HeaderLayout,
    exact: true,
    path: "/restaurants/details/:id",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/Restaurants_view")
    ),
  },

  {
    layout: HeaderLayout,
    exact: true,
    path: "/in_restaurants/details/:id",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/In_network_view")
    ),
  },
  {
    layout: HeaderLayout,
    exact: true,
    path: "/common-pairings/:menuId/:restaurantId",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/PairingItemList")
    ),
  },

  // Account
  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: "/my-profile",
    element: lazy(() => import("./Component/Pages/Account/Index")),
  },
  {
    guard: Authenticated,
    RestaurantUserAccess: RestaurantUserAccess,
    layout: BaseLayout,
    exact: true,
    path: "/branch",
    element: lazy(() => import("./Component/Pages/RestaurantPanel/Branch")),
  },
  {
    guard: Authenticated,
    RestaurantGuard: RestaurantGuard,
    layout: BaseLayout,
    exact: true,
    path: "/carousel",
    element: lazy(() => import("./Component/Pages/RestaurantPanel/RestaurantCarousel/Index")),
  },
  {
    guard: Authenticated,
    RestaurantGuard: RestaurantGuard,
    layout: BaseLayout,
    exact: true,
    path: "/add-menu-item",
    element: lazy(() =>
      import("./Component/Pages/RestaurantPanel/Menu/AddMenu")
    ),
  },
  {
    guard: Authenticated,
    RestaurantGuard: RestaurantGuard,
    layout: BaseLayout,
    exact: true,
    path: "/edit-menu-item/:menuId",
    element: lazy(() =>
      import("./Component/Pages/RestaurantPanel/Menu/EditMenu")
    ),
  },
  {
    layout: BaseLayout,
    exact: true,
    path: "/feedback/:id/:userId/:restaurantId",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/FeebackForm")
    ),
  },
  {
    guard: Authenticated,
    RestaurantGuard: RestaurantGuard,
    layout: HeaderLayout,
    exact: true,
    path: "/history",
    element: lazy(() =>
      import("./Component/Pages/RestaurantPanel/History/Index")
    ),
  },
  {
    layout: HeaderLayout,
    exact: true,
    path: "/likes/:id",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/RestaurantLikePage")
    ),
  },
  {
    layout: HeaderLayout,
    exact: true,
    path: "/product-likes/:menuId/:restaurantId",
    element: lazy(() =>
      import("./Component/Pages/Restaurants/ProductLikePage")
    ),
  },

  ///404 Page

  {
    layout: BaseLayout,
    exact: true,
    path: "*",
    element: lazy(() => import("./Component/PageNotFound")),
  },
];
